<template>
  <base-btn
    block
    color="success"
    @click="handlerClick"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $t(keyTextButtonTranslate) }}
  </base-btn>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    handlerClick: {
      type: Function,
      required: true,
    },
    keyTextButtonTranslate: {
      type: String,
      required: true,
    },
  },
};
</script>
